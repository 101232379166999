import { render, staticRenderFns } from "./guide2.vue?vue&type=template&id=8c4432c8&"
import script from "./guide2.vue?vue&type=script&lang=js&"
export * from "./guide2.vue?vue&type=script&lang=js&"
import style0 from "./guide2.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports